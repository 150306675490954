export const TaskContentType = {
  TEACHER_CONTENT: "TEACHER_CONTENT",
  BODY: "BODY",
};

export const CourseExecManagementMode = {
  OVERVIEW: "OVERVIEW",
  TIMELINE: "TIMELINE",
  TEACHER: "TEACHER",
  STUDENT: "STUDENT",
};

export const CourseType = {
  PROJECT: "PROJECT",
  MICRO_UNIT: "MICRO_UNIT",
};

export const CourseTypeDisplayText = {
  PROJECT: "Project",
  MICRO_UNIT: "Micro-unit",
};

export const RoleType = {
  ADMIN: "ADMIN",
  TEACHER: "TEACHER",
  STUDENT: "STUDENT",
};

export const CourseExecStatus = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  INACTIVE: "INACTIVE",
};

export const TrbStudentResponseStatus = {
  ACTIVE: "ACTIVE",
  SUBMIT: "SUBMIT",
};

export const StoragePaths = {
  WORK_PATH: "work/",
};

export const MAX_STUDENT_WORK_FILE_SIZE = 25; //MB
export const MAX_FILENAME_SIZE = 70;
export const MAX_ENCODED_FILENAME_SIZE = MAX_FILENAME_SIZE + 20; // 10 spaces

export const MAX_USER_ENROLL_SIZE = 100;

export const MAX_COOKIE_AGE = 24 * 60 * 60 * 1000; //ms

export const UserRegisterStatus = {
  NEW: "NEW",
  EXISTING: "EXISTING",
};

export const TEXT_FIELD_MAX_LENGTH = {
  SCHOOL_TITLE: "64",
  COUNTRY: "256",
  PROJECT_TITLE: "64",
  COURSE_TITLE: "64",
  SECTION_TITLE: "32",
  CLASSROOM_TITLE: "64",
  CLASSROOM_SUBJECT_NAME: "64",
  URL: "256",
};

export const STUDENT_TASK_FEEDBACK_REASONS = {
  positive: [
    "This was interesting!",
    "I liked that it was hands-on",
    "This was fun!",
  ],
  negative: ["This was boring", "This was too hard", "I didn't learn anything"],
};

export const TEACHER_TASK_FEEDBACK_REASONS = {
  positive: [
    "Practical & hands-on",
    "Difficulty level was just right",
    "Interesting & engaging",
    "Made learning relevant",
    "Easy to teach/deliver",
    "Helped build students' skills",
  ],
  negative: [
    "Not hands-on enough",
    "Too hard for students",
    "Task took too long",
    "Didn't support curriculum",
    "Difficult to teach/deliver",
    "Students lacked required skills",
  ],
};

export const ILLUSTRATIONS = {
  LOGO: {
    MAIN_LOGO:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/logo/logo.png",
  },
  SIGN_IN_SLIDER: [
    "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/sign-in-slider/Illustration21.jpeg",
    "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/sign-in-slider/Illustration22.jpeg",
    "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/sign-in-slider/Illustration23.jpeg",
  ],
  EXPLORE_IMAGES: {
    CIVICS_CITIZENSHIP:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/Civics&Citizenship.png",
    ECONOMICS_BUSINESS:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/Economics&Business.png",
    ELECTIVE:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/Elective.png",
    ENGLISH:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/English.png",
    GEOGRAPHY:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/Geography.png",
    HISTORY:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/History.png",
    MATHS:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/Maths.png",
    SCIENCE:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/subject-card-icons-v2/Science.png",
  },
  ZERO_RESULT: {
    SEARCH_COURSE:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/zero-result-pages/Search-page.png",
    FAVOURITE_COURSE:
      "https://d2iy5t7jkj1286.cloudfront.net/public/illustrations/zero-result-pages/Favourites-page.png",
  },
};

export const SSO_CONFIG = { 
  au: "https://d2qyc2vtjmrm64.cloudfront.net/public/org-sso/au.json",
};

export const COUNTRY_INFO = {
  au: {
    fullName: "Australia",
    states: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"],
    subjects: [
      "Civics & Citizenship",
      // "Design & Technologies",
      // "Digital Technologies",
      "Economics & Business",
      "Elective",
      "English",
      "Geography",
      // "Health & PE",
      "History",
      // "Mathematics",
      // "Media Arts",
      "Science",
      // "Visual Arts",
    ],
    exploreFeaturedSubjects: [
      { subjectName: "Science", image: ILLUSTRATIONS.EXPLORE_IMAGES.SCIENCE },
      {
        subjectName: "Geography",
        image: ILLUSTRATIONS.EXPLORE_IMAGES.GEOGRAPHY,
      },
      { subjectName: "History", image: ILLUSTRATIONS.EXPLORE_IMAGES.HISTORY },
      { subjectName: "English", image: ILLUSTRATIONS.EXPLORE_IMAGES.ENGLISH },
      { subjectName: "Elective", image: ILLUSTRATIONS.EXPLORE_IMAGES.ELECTIVE },
    ],
    levels: [
      // 1, 2,
      3, 4, 5, 6, 7, 8, 9, 10,
      // 11, 12
    ],
    yearLevelType: "NUMERIC",
    yearLevelPrefix: "Year",
  },
  za: {
    fullName: "South Africa",
    states: [],
    subjects: [
      // "Creative Arts",
      "Economic & Management Sciences",
      "Elective",
      "English",
      // "Life Skills",
      // "Mathematics",
      "Natural Sciences",
      "Social Sciences",
      // "Technology",
    ],
    exploreFeaturedSubjects: [
      {
        subjectName: "Natural Sciences",
        image: ILLUSTRATIONS.EXPLORE_IMAGES.SCIENCE,
      },
      {
        subjectName: "Social Sciences",
        image: ILLUSTRATIONS.EXPLORE_IMAGES.GEOGRAPHY,
      },
      { subjectName: "English", image: ILLUSTRATIONS.EXPLORE_IMAGES.ENGLISH },
      { subjectName: "Elective", image: ILLUSTRATIONS.EXPLORE_IMAGES.ELECTIVE },
    ],
    levels: [
      // 1, 2, 3,
      4, 5, 6, 7, 8, 9,
      // 10, 11, 12
    ],
    yearLevelType: "NUMERIC",
    yearLevelPrefix: "Grade",
  },
  sg: {
    fullName: "Singapore",
    states: [],
    subjects: [],
    levels: [],
  },
  uk: { fullName: "UK", states: [], subjects: [], levels: [] },
  usa: { fullName: "USA", states: [], subjects: [], levels: [] },
};

export const AWS_REGION_COUNTRY_MAP = {
  "ap-southeast-2": "au",
  "af-south-1": "za",
};

export const SYSTEM_MESSAGES = {
  ERROR: {
    EXEEDED_MAX_FILENAME_SIZE:
      `File name is too long. ` +
      `Reduce file name to less than ${MAX_FILENAME_SIZE} characters and try again`,
  },
};

export const DATETIME_FORMAT = "D MMM YYYY h:mm a";

export const TASK_RESOURCE_BLOCK_TYPE = {
  QUESTION: "QUESTION",
  TABLE: "TABLE",
  CONTENT: "CONTENT",
  RUBRIC: "RUBRIC",
};

export const GENERIC_EDITOR_MODES = {
  DISPLAY: "DISPLAY",
  EDIT: "EDIT",
  READ_ONLY: "READ_ONLY",
};

export const EXPLORE_PAGE_MODES = {
  EXPLORE: "EXPLORE",
  SEARCH: "SEARCH",
  PREVIEW: "PREVIEW",
  FAVOURITE: "FAVOURITE",
};

export const RUBRIC_LEVELS_TITLE = {
  3: ["At Standard", "Approaching Standard", "No Evidence"],
  4: ["Above Standard", "At Standard", "Approaching Standard", "No Evidence"],
  5: [
    "Above Standard",
    "At Standard",
    "Approaching Standard",
    "Needs Improvement",
    "No Evidence",
  ],
};

export const GA_ACTIONS = {
  AUTHENTICATION: {
    LOGIN: "LOGIN",
    OTP_LOGIN: "OTP_LOGIN",
    SUBSCRIPTION_EXPIRED: "SUBSCRIPTION_EXPIRED",
  },
  EXPLORE_SEARCH: {
    SEARCH: "SEARCH_QUERY",
    CLICK_OVERVIEW: "CLICK_OVERVIEW",
    CLICK_PREVIEW: "CLICK_PREVIEW",
    CLICK_CREATE_CLASSROOM: "CLICK_CREATE_CLASSROOM",
    CLICK_SIGNUP: "CLICK_SIGNUP",
    CLICK_SIGNIN: "CLICK_SIGNIN",
    CREATE_CLASSROOM_OVERVIEW: "CREATE_CLASSROOM_OVERVIEW",
    CREATE_CLASSROOM_PREVIEW: "CREATE_CLASSROOM_PREVIEW",
  },
  ASSESSMENT: {
    STUDENT_SUBMIT: "STUDENT_SUBMIT",
    TEACHER_ASSESS: "TEACHER_ASSESS",
  },
};

export const CLASSROOM_TIMELINE_ACTION_TYPES = {
  CREATE_CLASSROOM: "CREATE_CLASSROOM",
  MANAGE_TEACHER: "MANAGE_TEACHER",
  MANAGE_STUDENT: "MANAGE_STUDENT",
  TASK_ACCESS: "TASK_ACCESS",
};

export const USER_PLAN_TYPES = {
  PREVIEW: {
    value: "PREVIEW",
    displayText: "Preview",
  },
  TRIAL: {
    value: "TRIAL",
    displayText: "Trial",
  },
  BASIC_SUBSCRIPTION: {
    value: "BASIC_SUBSCRIPTION",
    displayText: "Subscription",
  },
};

export const WEBSITE_LINKS = {
  ROOT: "https://www.curaeducation.com",
  LEARN: "https://www.curaeducation.com/learn",
  HELP: "https://www.curaeducation.com/help",
  PRIVACY: "https://www.curaeducation.com/legal/privacy",
  TERMS: "https://www.curaeducation.com/legal/terms",
  CONTACT_US: "https://www.curaeducation.com/contact-us",
};
